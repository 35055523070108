import React, { useState } from "react";
import cookies from "js-cookie";
import { NavLink, useNavigate } from "react-router-dom";

import "./Navigation.css";


import { images } from "../../constants";
import { navigationD } from "../../data";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

import BasketItem from '../BasketItem/BasketItem';
import { sendMessage } from "../../utlis/sendMessage";

import { useDispatch, useSelector } from 'react-redux'
import { removeProductsAction } from '../../store/redusers/basketReduser'
import { enableModalAction } from '../../store/redusers/completeModalReduser'

function Navigation() {
  const socialData = [
    {
      id: 1,
      name: "vk",
      href: "https://vk.com/wushuapp",
      imgSrc: images.vk_icon,
    },
    {
      id: 2,
      name: "facebook",
      href: "https://www.facebook.com/profile.php?id=100004441338020&mibextid=LQQJ4d",
      imgSrc: images.fb_icon,
    },
    {
      id: 3,
      name: "instagram",
      href: "https://instagram.com/udao_wushu",
      imgSrc: images.instagram_icon,
    },
  ];

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function setLanguage(lng) {
    window.location.reload();
    i18next.changeLanguage(lng);
  }

  const [activeMenu, setActiveMenu] = useState("")
  
  const [activeBasket, setActiveBasket] = useState("")


  const basketItems = useSelector(state => state.basketReduser.goods)

  const hendleCountChange = (id, value) => basketItems[basketItems.findIndex(i => i.id == id)].count = value



  return (
    <>
      <div className="navigation">
        <div className="navigation__upper">
          <div className="container">
            <div className="navigation__upper__wrapper">
              <div className="navigation__upper-contacts">
                {socialData.map((item) => (
                  <a href={item.href} key={item.id} target="_blank">
                    <img src={item.imgSrc} alt={item.name} />
                  </a>
                ))}
              </div>
              <div className="navigation__upper-callback">
                <a href="tel:+79816830576">
                  {t("telephone")}{t("number")}
                </a>
                <a href="/">{t("whatsapp")}{t("number")}</a>
              </div>
              <div className="navigation__upper-localization">
                <div
                  onClick={() => setLanguage("ru")}
                  className={`button ${
                    currentLanguageCode === "ru" ? "active" : ""
                  }`}
                >
                  RUS
                </div>
                <div
                  onClick={() => setLanguage("en")}
                  className={`button ${
                    currentLanguageCode === "en" ? "active" : ""
                  }`}
                >
                  ENG
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navigation__lower">
          <div className="container">
            <div className="navigation__lower__wrapper">
              <div className="logo">
                <a href="/"><img src={images.logo} alt="logo" /></a>
              </div>
              <nav>
                <ul>
                  {navigationD.map((item, idx) => (
                    <li key={idx}>
                      <NavLink to={item.href}>{t(`${item.name}`)}</NavLink>
                      <div className="dot" />
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="btns">
                <button className="basket-btn" onClick={() => setActiveBasket("active")} >
                  <img src={images.basket} alt="shop-basket" />
                </button>
                <div className="burger-menu__btn" onClick={() => setActiveMenu("active")}>
                    <img src={images.menu} alt="menu" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`navigation__burger-menu ${activeMenu}`}>
        <div className="navigation__burger-menu__wrapper">
          <div className="navigation__burger-menu-callback">
            <a href="tel:+79816830576">
              {t("telephone")}{t("number")}
            </a>
            <a href="/">{t("whatsapp")}{t("number")}</a>
          </div>

          <div className="navigation__burger-menu-localization">
            <div
              onClick={() => setLanguage("ru")}
              className={`button ${
                currentLanguageCode === "ru" ? "active" : ""
              }`}
            >
              RUS
            </div>
            <div
              onClick={() => setLanguage("en")}
              className={`button ${
                currentLanguageCode === "en" ? "active" : ""
              }`}
            >
              ENG
            </div>
          </div>

          <nav>
            <ul>
              {navigationD.map((item, idx) => (
                <li key={idx}>
                  <NavLink to={item.href}>{t(`${item.name}`)}</NavLink>
                </li>
              ))}
            </ul>
          </nav>
          
          <div className="navigation__burger-menu-contacts">
            {socialData.map((item) => (
              <a href={item.href} key={item.id} target="_blank">
                <img src={item.imgSrc} alt={item.name} />
              </a>
            ))}
          </div>
        </div>
        <div className="close-btn" onClick={() => setActiveMenu("")}>
          <img src={images.close} alt="close"></img>
        </div>
      </div>

      <div className={`basket ${activeBasket}`}>
            <div className='basket__wrapper'>
                <div className='basket__title'>
                  <div className="basket__title-row">
                    <div className="basket__title-text">
                      {t("cart")}
                    </div>
                    <img src={images.basketBlack} alt="basket" />
                  </div>
                </div>
                {basketItems.length > 0 ?
                      <>
                        <div className='basket__items'>
                          {
                            basketItems.map(item => (<BasketItem 
                              key={item.id}
                              id={item.id}
                              props={item.props} 
                              data={item.inputData}
                              onChange={hendleCountChange}/>))
                          }
                        </div>
                        <button className="basket__btn-reset" onClick={() => dispatch(removeProductsAction())} >{t('clear-to-cart')}</button>
                        <div className="basket__send">
                          <button 
                            className="btn"
                            onClick={() => {
                              try{
                                let basketInputData = 'Заказ с корзины: %0A'
                                let basketImagesData = [];
                                basketItems.forEach(e => {
                                  basketInputData += `%0A${e.props.title.ru} (кол-во. ${e.count}): %0A${JSON.stringify(e.inputData, null, '%0A').replace(/[{}"\s]/g, ' ')} %0A`
                                  basketImagesData.push(e.props.image)
                                });
                                sendMessage(basketInputData, basketImagesData)
                                setActiveBasket("")
                                navigate("/")
                                dispatch(enableModalAction())
                              }catch(ex){
                                alert(ex)
                              }
                            }}>
                              {t('call-back__form-send__btn')}
                            </button>
                        </div>
                      </>
                    :
                    <div className='basket__text'>{t('void-cart')}</div>
                    
                }             
            </div>
            <div className='close-btn' onClick={() => setActiveBasket("")} ><img src={images.black_close} alt="close" /></div>
        </div>
    </>
  );
}

export default Navigation;
